import Title from '../data.tit.js'
import ContData from './cont.data.js'
import ContList from './cont.list.js'
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function ContMain(props){
    let [tit] = useState(Title);
    let navigate = useNavigate();
    let [num, setNum] = useState(0);
    let [num1, setNum1] = useState(0);


    return(
        <>
        <div id="mainWrap">  
              <div className="inner">       
                <div className="main-top flex">
                  <p className='tit-b text-al-le'>2024 Main Exhibition of <br />Clayarch Gimhae Museum</p>
                  <img src="img/main_sq.png" className="icon" />
                  <p className='tit-b text-al-ri'>I’ll Show You <br />100% of Me</p>
                </div>
                <div className="main-center">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div className="img-box">
                    <img src="img/main_img.png" />
                    <img src="img/main_img01.png" className='img-ab' />
                  </div>
                  <p className='tit-b'>레드세다, 레지나킴, 비주, 핸즈인 팩토리<br /><i>Redseda, Regina Kim, Biju, Hands in Factory</i></p>
                </div>
                <div className="main-bottom flex">
                  <p className='tit-b text-al-le'>2024 <br />04.27-10.27</p>
                  <p className='tit-b text-al-ri'>클레이아크김해미술관 <br />돔하우스 중앙홀 / 제1전시실</p>
                </div>
              </div>
            </div>
          <div id="infoWrap" ref={props.scrollRef}>
              <div className="inner">
                <div className="list-tit-wr">
                  <ul className="tit-wr flex">
                    <li className="tit-b text-al-le">{tit[0].title}</li>
                    <li className="tit-b text-al-ri">{tit[0].titleEn}</li>
                  </ul>
                   <div className={'zoom-btn-wr ' + ' flex ' + 'minus'+ (19 + num) + ' puls'+ (19 + num)}>
                    <button onClick={()=>{ setNum(num - 3)}} className='zoom-btn zoom-btn-minus'><img src={process.env.PUBLIC_URL + '/img/icon_btn_minus.png'} /></button>
                    <button onClick={()=>{ setNum(num + 3)}} className='zoom-btn zoom-btn-puls'><img src={process.env.PUBLIC_URL + '/img/icon_btn_puls.png'} /></button>
                  </div>
                </div>              
                <div className={'cont '+'font-'+(19 + num)}>
                  <ContData />
                </div>
              </div>
            </div>
            <div id="drawWrap">
              <div className="inner pa-t0 pa-b4">
                <div className="list-tit-wr">                  
                  <ul className="tit-wr flex">
                    <li className="tit-b text-al-le">{tit[1].title}</li>
                    <li className="tit-b text-al-ri">{tit[1].titleEn}</li>
                  </ul>
                  <div className={'zoom-btn-wr ' + ' flex ' + 'minus'+ (19 + num1) + ' puls'+ (19 + num1)}>
                    <button onClick={()=>{ setNum1(num1 - 3)}} className='zoom-btn zoom-btn-minus'><img src={process.env.PUBLIC_URL + '/img/icon_btn_minus.png'} /></button>
                    <button onClick={()=>{ setNum1(num1 + 3)}} className='zoom-btn zoom-btn-puls'><img src={process.env.PUBLIC_URL + '/img/icon_btn_puls.png'} /></button>
                  </div>
                </div>              
                <div className={'cont '+'font-'+(19 + num1)}>
                  <div className="draw-img-wr">
                    <div className="draw-img draw-img01">
                      <div className="img-box">
                        <button onClick={()=>{navigate('./pages/draw')}} className='img-btn img-btn-seda1'></button>
                        <button onClick={()=>{navigate('./pages/draw1')}} className='img-btn img-btn-kim1'></button>
                        <button onClick={()=>{navigate('./pages/draw1')}} className='img-btn img-btn-kim2'></button>
                        <button onClick={()=>{navigate('./pages/draw2')}} className='img-btn img-btn-biju1'></button>
                        <button onClick={()=>{navigate('./pages/draw2')}} className='img-btn img-btn-biju2'></button>
                        <button onClick={()=>{navigate('./pages/draw3')}} className='img-btn img-btn-hands1'></button>
                        <button onClick={()=>{navigate('./pages/draw3')}} className='img-btn img-btn-hands2'></button>
                        <button onClick={()=>{navigate('./pages/draw3')}} className='img-btn img-btn-hands3'></button>
                        <button onClick={()=>{navigate('./pages/draw3')}} className='img-btn img-btn-hands4'></button>
                        <img src="img/img_space01.jpg"  />
                      </div>
                    </div>
                  </div>
                  <ContList />                  
                  <div className="cont-btn">
                      <button onClick={()=>{navigate('/')}}><img src={process.env.PUBLIC_URL + '/img/icon_home.png'} alt="home button" /></button>   
                      <button onClick={()=>{navigate('./pages/draw')}}><img src={process.env.PUBLIC_URL + '/img/icon_next.png'} alt="next button" /></button>      
                  </div>
                </div>
              </div>
            </div>               
        </>
    )
}


// export default ContMain;
export default ContMain;
let DrawData = [
  //레드세다
  {
    title : 'LUCKY POOL',
    category: 'Acrylic & mixed media on canvas',
    size : '60.6x72.7, 2024'
  },{
    title : 'surf in your life',
    category: 'Acrylic & mixed media on canvas',
    size : '80x80, 2023'
  },{
    title : 'brave adventurers',
    category: 'Acrylic & mixed media on canvas',
    size : '80x80, 2023'
  },{
    title : 'MY NAME IS FLOR',
    titlesub : '(내이름은플로)',
    category: 'ARTTOY',
    size : '30x23, 2023'
  },{
    title : 'GROWING',
    category: 'Acrylic & mixed media on canvas',
    size : '91x91, 2023'
  },{
    title : 'winter blossom',
    category: 'Acrylic & mixed media on canvas',
    size : '116.8x91, 2023'
  },{
    title : 'SPONGEBOB WITH SEDA ',
    category: 'Acrylic & mixed media on canvas',
    size : '130.3x130.3, 2022'
  },{
    title : 'MY WORLD',
    category: 'Acrylic & mixed media on canvas',
    size : '100x80.3, 2022'
  },{
    title : 'DREAM COME TRUE',
    category: 'Acrylic & mixed media on canvas',
    size : '100x80.3, 2022'
  },{
    title : 'MY AMBITION ',
    category: 'Acrylic & mixed media on canvas',
    size : '130.3x130.3, 2022'
  },{
    title : 'A slow mountain',
    category: 'Acrylic & mixed media on canvas',
    size : '116.8x91, 2023'
  },{
    title : 'TO ME FROM ME',
    category: 'Acrylic & mixed media on canvas',
    size : '40x80, 2023'
  },{
    title : 'HOMEGROUND',
    category: 'Acrylic & mixed media on canvas',
    size : '116.8x91, 2022'
  },{
    title : 'BEST FRIEND FOREVER ',
    category: 'Acrylic & mixed media on canvas',
    size : '116.8x91, 2022'
  },{
    title : 'supertime',
    category: 'Acrylic & mixed media on canvas',
    size : '60.6x72.7, 2023'
  },{
    title : 'Carefree',
    category: 'Acrylic & mixed media on canvas',
    size : '91x91, 2023'
  }
]


let DrawData1 = [
  //레지나킴
  {
    title : 'Harmony',
    titlebadge : "가우디재단 소장 Gaudi Foundation's Collected",
    badgeClass : "badge",
    category: '30sec, Digital collage & moving image',
    size : '2024',
    onClass1 : "on",
    title1 : 'My universe',    
    category1: '30sec, Digital collage & moving image',
    size1 : '2024'
  },{
    title : '함께',
    titlesub : '(Together)',
    category: '디아섹 Diasec',
    size : '90x60cm, 2021'
  },{
    title : 'For Ukraine',
    category: 'Digital collage & Lenticular printing',
    size : '80x80, 2023'
  },{
    title : 'Overdressed',
    category: '프린트&액자',
    size : '약 115x115cm, 2022'
  },{
    title : 'Pray for you',
    category: '프린트&액자',
    size : '약 115x115cm, 2022'
  },{    
    title : '경계선 너머',
    titlesub : 'Beyond Borders',
    size : '2021',
    onClass1 : "on",
    title1 : '루머의 숲',
    titlesub1 : 'The forest of Rumours',
    onClass2 : "on",
    size1 : '2023',
    title2 : '눈먼 자들의 믿음',
    titlesub2 : 'The Blind Faith',
    onClass3 : "on",
    size2 : '2023',
    title3 : '상실의 섬',
    titlesub3 : 'The Island of Loss',
    size3 : '2023',
    onClass4 : "on",
    title4 : '디지털 바벨탑',
    titlesub4 : 'The Digital Babel Tower',
    size4 : '2023'
  },{
    title : 'Life racing',
    category: '디아섹 Diasec',
    size : '112x160cm, 2022'
  },{
    title : 'The Dream city',
    category: '디아섹 Diasec',
    size : '112x145cm, 2022'
  },{
    title : '드림랜드',
    titlesub : '(The Dreamland)',
    category: '디아섹 Diasec',
    size : '180x60cm, 2021'
  },{
    title : '경계선 너머',
    titlesub : 'Beyond Borders',
    size : '2021',
    onClass1 : "on",
    title1 : '드림시티',
    titlesub1 : 'The Dream City',
    onClass2 : "on",
    size1 : '2022',
    title2 : '드림랜드',
    titlesub2 : 'The Dream Land',
    size2 : '2021',
    onClass3 : "on",
    title3 : '라이프레이싱',
    titlesub3 : 'The Life Racing',
    size3 : '2022',
    onClass4 : "on",
    title4 : '꿈은 크게',
    titlesub4 : 'Dream Big',
    size4 : '2022'
  }
]

let DrawData2 = [
  //비주
  {
    title : 'Grand Opening of Pilly’s Butcher Shop',
    titlesub : '',
    category: 'Digital canvas',
    size : '4K, 4.33GB, 2022'
  },{
    title : '먹어도 먹어도 배고픈',
    titlesub : 'Hungry Head',
    category: 'Acrylic on Canvas',
    size : '60.6x60.6cm, 2022'
  },{
    title : '몸에 좋습니다',
    titlesub : "it's good for your body",
    category: 'Acrylic on Canvas',
    size : '116.8x91.0cm, 2024'
  },{
    title : '만수무강을 비는 항아리[약탕기]_2',
    titlesub : 'Genie for your wellness.',
    category: 'Acrylic on Canvas',
    size : '90.9x65.1cm, 2023'
  },{
    title : '만수무강을 비는 항아리[약탕기]_4',
    titlesub : 'Genie for your wellness.',
    category: 'Acrylic on Canvas',
    size : '90.9x65.1cm, 2023'
  },{
    title : '만수무강을 비는 항아리[약탕기]_3',
    titlesub : 'Genie for your wellness.',
    category: 'Acrylic on Canvas',
    size : '90.9x65.1cm, 2023'
  },{
    title : '기원',
    titlesub : 'praying',
    category: 'Acrylic on Canvas',
    size : '100.0x72.7cm, 2024'
  },{
    title : 'Elevator',
    category: 'Digital canvas',
    size : '4K, 142MB, 2023'
  },{
    title : 'Out of the blue : 청천벽력 ',
    category: 'Acrylic on Canvas',
    size : '100.0x72.7cm, 2024'
  },{
    title : 'Pilly’s Launderette',
    category: 'Pilly’s Launderette',
    size : '4K, 5.57GB, 2022'
  }
]

let DrawData3 = [
  //핸즈
  {
    title : 'YETTI Series',
    size : 'PVC, ABS 20(h) cm'
  },{
    title : 'JINX, KLED,ORNN,THAM KENCH',
    titlesub : '(Hands in factory X League of Legends)',
    size : 'Resin 12~22(h) cm'
  },{
    title : 'LAZY SUNDAY AFTERNOON',
    size : 'Resin 12(h) cm'
  },{
    title : 'LIL’TIE',
    size : 'Resin 15(h) cm'
  },{
    title : 'OLIVER ver. SLC X ALL DAY PIZZA',
    size : 'Resin 22(h) cm '
  },{
    title : 'MARANIC 1',
    size : 'Resin 18 (h) cm'
  },{
    title : 'TUB TIME ',
    size : 'Resin 12 (h) cm'
  },{
    title : 'GREEN ver. GREEN DAY',
    size : 'Acrlyc on Paper 30F (727*909)'
  },{
    title : 'FFALO ver.102th',
    size : 'Acrlyc on Paper 30F (727*909)'
  },{
    title : 'LIFE',
    size : 'Glass, Resin / 45x45x60(h) cm'
  },{
    title : 'LIFE',
    size : 'Glass, Resin / 45x45x30(h) cm'
  },{
    title : 'LIFE',
    size : 'Glass, Resin / 30x30x45(h) cm'
  },{
    title : 'LIFE',
    size : 'Glass, Resin / 20x20x30(h) cm'
  },{
    title : 'FABBO ',
    size : 'Resin / 15x13x12(h) cm'
  },{
    title : 'VINE : HAZARD painter ',
    sizeOn : 'on',
    size : 'Resin / (L) 9x5.5x14(h) cm',
    sizeR : '(R) 15x14x35(h) cm'
  },{
    title : 'TIRED DAY : HAZARD',
    size : 'Polystone / 9.5x11x21(h) cm',
    onClass1 : 'on',
    title1 : 'TIRED DAY : COBI',
    size1 : 'Polystone / 8.5x11x19(h) cm'
  },{
    title : 'Polystone / 8.5x11x19(h) cm',
    size : 'Resin / 8x7.5x15(h) cm'
  },{
    title : 'TIRED DAY : MOSSY',
    size : 'Polystone / 10.5x15x20(h) cm'
  },{
    title : 'PIEBALD',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'MELANOID',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'ALBINO',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'WILD TYPE',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'SILVER DALMATIAN',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'CHIMERA',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'GFP',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'GOLDEN',
    size : 'Acrylin on canvas / 8S'
  },{
    title : 'TAI GONG',
    size : 'Resin 22 (h) cm',
    onClass1: 'on',
    title1 : 'RUNNING HORNS BLIND SERIES 01. IWGD',
    size1 : 'PVC, ABS 10 (h) cm'
  },{
    brTag : 'on',
    title : 'VAGAB ‘S JERSEY',
    brTitle : 'ver. Original(X 김정윤)',
    size : 'Resin 20 (h) cm',
    onClass1: 'on',
    title1 : 'BOY WEAR BLUE ',
    brTitle1 : '(X BAZ BON)',
    size1 : 'Resin 20 (h) cm'
  },{
    title : 'BLACK TIGER EDITION',
    titlesub : '(Hands in factory X League of Legends)',
    category: '',
    size : 'PVC, ABS 7 (h) cm'
  },{
    title : 'MIGHTY HORN',
    size : 'Resin 150 (h) cm'
  },{
    title : 'DAY WRAITH ver. X KIDDO',
    size : 'Resin 150 (h) cm'
  }
]

export {DrawData, DrawData1, DrawData2, DrawData3};


import { useState } from "react";
import ContList from './cont.list.js'
import { useNavigate } from 'react-router-dom';
import { DrawData1 } from './draw.data';
import ZoomBtn from './zoom.btn.js'


function Draw1(props){
    let navigate = useNavigate();
    let [num, setNum] = useState(0);

    return(
            <div id="drawWrap" className='draw-kim'>
              <div className="inner pa-t0 pa-b4">
                <div className="list-tit-wr">
                  <ul className="tit-wr flex" onClick={()=>{navigate('../pages/drawAll')}}>
                    <li className="tit-b text-al-le">{props.tit[2].title}</li>
                    <li className="tit-b text-al-ri">{props.tit[2].titleEn}</li>
                  </ul>
                  <div className={'zoom-btn-wr ' + ' flex ' + 'minus'+ (19 + num) + ' puls'+ (19 + num)}>
                    <button onClick={()=>{ setNum(num - 3)}} className='zoom-btn zoom-btn-minus'><img src={process.env.PUBLIC_URL + '/img/icon_btn_minus.png'} /></button>
                    <button onClick={()=>{ setNum(num + 3)}} className='zoom-btn zoom-btn-puls'><img src={process.env.PUBLIC_URL + '/img/icon_btn_puls.png'} /></button>
                  </div>
                </div>              
                <div className={'cont '+'font-'+(19 + num)}>
                  <div className="draw-img-wr">
                    <div className="draw-img draw-img01">
                      <div className="img-box">
                        <img src={process.env.PUBLIC_URL + '/img/img_sp_kim.jpg'} />
                      </div>
                      <div className="artist-tit">
                        <span>Artist</span>
                        <h3>레지나킴 <br/>Regina Kim</h3>
                      </div>
                    </div>                    
                  </div>
                  <ul className="data-list-wr">
                  {
                    DrawData1.map(function(a, i){
                        return(
                          <>
                                <li className='data-list flex' key={i}>
                                    <div className="num-ci"><span>{i + 1}</span></div>
                                    <img src={process.env.PUBLIC_URL + '/img/img_list1_' + (i+1) + '.png'} />
                                    <div className="list-tit">
                                        <div className="tit-b">{DrawData1[i].title}<i className={DrawData1[i].badgeClass}>{DrawData1[i].titlebadge}</i><i className='i-txt'>{DrawData1[i].titlesub}</i></div>
                                        <p>{DrawData1[i].category}</p>
                                        <p>{DrawData1[i].size}</p>
                                    </div>
                                </li>
                                <li className={ DrawData1[i].onClass1 + ` data-list ` + `add ` + `flex`}>
                                    <div className="num-ci"></div>
                                    <img src={process.env.PUBLIC_URL + '/img/img_list1_1_' + (i+2) + '.png'} />
                                    <div className="list-tit">
                                        <div className="tit-b">{DrawData1[i].title1} <i className='i-txt'>{DrawData1[i].titlesub1}</i></div>
                                        <p>{DrawData1[i].category1}</p>
                                        <p>{DrawData1[i].size1}</p>
                                    </div>
                                </li>
                                <li className={ DrawData1[i].onClass2 + ` data-list ` + `add ` + `flex`}>
                                    <div className="num-ci"></div>
                                    <img src={process.env.PUBLIC_URL + '/img/img_list1_1_' + (i+3) + '.png'} />
                                    <div className="list-tit">
                                        <div className="tit-b">{DrawData1[i].title2} <i className='i-txt'>{DrawData1[i].titlesub2}</i></div>
                                        <p>{DrawData1[i].category2}</p>
                                        <p>{DrawData1[i].size2}</p>
                                    </div>
                                </li>
                                <li className={ DrawData1[i].onClass3 + ` data-list ` + `add ` + `flex`}>
                                    <div className="num-ci"></div>
                                    <img src={process.env.PUBLIC_URL + '/img/img_list1_1_' + (i+4) + '.png'} />
                                    <div className="list-tit">
                                        <div className="tit-b">{DrawData1[i].title3} <i className='i-txt'>{DrawData1[i].titlesub3}</i></div>
                                        <p>{DrawData1[i].category3}</p>
                                        <p>{DrawData1[i].size3}</p>
                                    </div>
                                </li>
                                <li className={ DrawData1[i].onClass4 + ` data-list ` + `add ` + `flex`}>
                                    <div className="num-ci"></div>
                                    <img src={process.env.PUBLIC_URL + '/img/img_list1_1_' + (i+5) + '.png'} />
                                    <div className="list-tit">
                                        <div className="tit-b">{DrawData1[i].title4} <i className='i-txt'>{DrawData1[i].titlesub4}</i></div>
                                        <p>{DrawData1[i].category4}</p>
                                        <p>{DrawData1[i].size4}</p>
                                    </div>
                                </li>
                          </>
                        )
                      })
                    }
                  </ul>
                  <ContList />
                  <div className="cont-btn">
                    <button onClick={()=>{navigate('../pages/draw')}}><img src={process.env.PUBLIC_URL + '/img/icon_prev.png'} alt="next button" /></button>
                    <button onClick={()=>{navigate('/')}}><img src={process.env.PUBLIC_URL + '/img/icon_home.png'} alt="home button" /></button>   
                    <button onClick={()=>{navigate('../pages/draw2')}}><img src={process.env.PUBLIC_URL + '/img/icon_next.png'} alt="next button" /></button>      
                </div>
                  
                </div>
              </div>
            </div>
    )
}

export default Draw1;
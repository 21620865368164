import Title from '../data.tit.js'
import ContData from './cont.data.js'
import ContList from './cont.list.js'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function DrawAll(props){
    let [tit] = useState(Title);
    let navigate = useNavigate();
    let [num, setNum] = useState(0);


    return(  
          <div id="drawWrap">
              <div className="inner pa-t0 pa-b4">
                <div className="list-tit-wr">                  
                  <ul className="tit-wr flex">
                    <li className="tit-b text-al-le">{tit[1].title}</li>
                    <li className="tit-b text-al-ri">{tit[1].titleEn}</li>
                  </ul>
                  <div className={'zoom-btn-wr ' + ' flex ' + 'minus'+ (19 + num) + ' puls'+ (19 + num)}>
                    <button onClick={()=>{ setNum(num - 3)}} className='zoom-btn zoom-btn-minus'><img src={process.env.PUBLIC_URL + '/img/icon_btn_minus.png'} /></button>
                    <button onClick={()=>{ setNum(num + 3)}} className='zoom-btn zoom-btn-puls'><img src={process.env.PUBLIC_URL + '/img/icon_btn_puls.png'} /></button>
                  </div>
                </div>              
                <div className={'cont '+'font-'+(19 + num)}>
                  <div className="draw-img-wr">
                    <div className="draw-img draw-img01">                      
                      <div className="img-box">
                        <button onClick={()=>{navigate('../pages/draw')}} className='img-btn img-btn-seda1'></button>
                        <button onClick={()=>{navigate('../pages/draw1')}} className='img-btn img-btn-kim1'></button>
                        <button onClick={()=>{navigate('../pages/draw1')}} className='img-btn img-btn-kim2'></button>
                        <button onClick={()=>{navigate('../pages/draw2')}} className='img-btn img-btn-biju1'></button>
                        <button onClick={()=>{navigate('../pages/draw2')}} className='img-btn img-btn-biju2'></button>
                        <button onClick={()=>{navigate('../pages/draw3')}} className='img-btn img-btn-hands1'></button>
                        <button onClick={()=>{navigate('../pages/draw3')}} className='img-btn img-btn-hands2'></button>
                        <button onClick={()=>{navigate('../pages/draw3')}} className='img-btn img-btn-hands3'></button>
                        <button onClick={()=>{navigate('../pages/draw3')}} className='img-btn img-btn-hands4'></button>
                        <img src={process.env.PUBLIC_URL + "/img/img_space01.jpg"}  />
                      </div>
                    </div>
                  </div>
                  <ContList />                  
                  <div className="cont-btn">
                      <button onClick={()=>{navigate('/')}}><img src={process.env.PUBLIC_URL + '/img/icon_home.png'} alt="home button" /></button>   
                      <button onClick={()=>{navigate('../pages/draw')}}><img src={process.env.PUBLIC_URL + '/img/icon_next.png'} alt="next button" /></button>      
                  </div>
                </div>
              </div>
            </div>
    )
}


// export default ContMain;
export default DrawAll;
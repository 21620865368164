let Title = [
    {
        title : '전시소개',
        titleEn : 'Exhibition Introduction'
    },
    {
        title : '전시장 도면',
        titleEn : 'Drawing of an Exhibition Hall'
    },
    {
        title : '작품목록',
        titleEn : 'List of Works'
    }
]


export default Title;
import { useNavigate } from 'react-router-dom';

function ContList(){
    let navigate = useNavigate();

    return(
        <>
        <ul className="draw-list-info">
            <li>
                <span>자세한 작품정보를 원하시면 작가이름을 터치 해주세요!</span>
                <img src={process.env.PUBLIC_URL + '/img/draw_line.png'} className='icon'></img>
            </li>
        </ul>
        <ul className="draw-list">
            <li className="flex" onClick={()=>{navigate('../pages/draw')}}>레드세다 Red. Seda <span className="ci-1"></span></li>
            <li className="flex" onClick={()=>{navigate('../pages/draw1')}}>레지나킴 Regina. Kim <span className="ci-2"></span></li>
            <li className="flex" onClick={()=>{navigate('../pages/draw2')}}>비주 Biju <span className="ci-3"></span></li>
            <li className="flex" onClick={()=>{navigate('../pages/draw3')}}>핸즈인 팩토리 Hands in Factory <span className="ci-4"></span></li>
        </ul>
        </>
    )
};

export default ContList;
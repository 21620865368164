import { useState, useEffect } from 'react';
import './App.css';
import Title from './data.tit.js'
import DrawAll from './pages/drawAll.js'
import Draw from './pages/draw.js'
import Draw1 from './pages/draw1.js'
import Draw2 from './pages/draw2.js'
import Draw3 from './pages/draw3.js'
import ContMain from './pages/cont.main.js'
import { Routes, Route, useLocation } from 'react-router-dom';


function App() {
  let [tit] = useState(Title);
  

  
  let ScrollToTop = () => {
    let { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    
    return null;
  };

  //모바일에서 url 창 부분 크기 제외 vh
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
  });



  return (
    <div className="App">
      <ScrollToTop/>
      <Routes>        
          <Route path="/" element={<ContMain/>} />
          <Route path="/pages/drawAll" element={ <DrawAll tit={ tit } /> }/>
          <Route path="/pages/draw" element={ <Draw tit={ tit } /> }/>
          <Route path="/pages/draw1" element={ <Draw1 tit={ tit } /> } />
          <Route path="/pages/draw2" element={ <Draw2 tit={ tit } /> } />
          <Route path="/pages/draw3" element={ <Draw3 tit={ tit } /> } />
        </Routes>      
        <div id="fff"></div>
    </div>
  );
} 



export default App;

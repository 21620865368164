function ContData(){
    return(
        <>
        <div className="cont-kr pa-b36">
                  <p className="pa-b36">2006년 개관한 클레이아크김해미술관은 지난 
                    17년간 김해의 지역성과 건축도자미술관이라는 
                    정체성 구축을 위해 쉼 없이 달려왔다. 2012년 큐빅
                    하우스 전시공간 신축개관으로 동시대미술 전반을 
                    다루게 되면서 좀 더 풍부한 전시기획이 가능해 졌고,
                    2018년~2019년까지 개관이래 최다 관람객을 
                    기록하며 용이하지 못한 미술관 접근성의 단점을 
                    극복하고 대중의 일상에 스며들기 시작했다. <br/>
                    하지만 2020년 초, 예상치 못한 코로나 사태는 우리 
                    삶의 방식을 비대면으로 전환시키며 ‘미술관’이라는
                    물리적 공간의 한계와 마주하는 계기가 되었다.
                    </p>
                    <p>
                    온라인 전시문화 확산, 영상콘텐츠의 양적 증대와 
                    함께 포스트 펜데믹을 맞이한 우리는 ‘대중이 잠시 
                    온라인 세계로부터 해방되어 미술관 현실세계로 
                    이동하게 하는 것’을 목표로 정형화된 전시시스템을 
                    흔들어보고자 한다. 이번 전시는 전시장이 정적이며
                    수동적인 작품 감상의 공간이 아니라 능동적인 참여와 
                    즐거움, 사회적 메시지가 담긴 젊은 세대의 목소리를
                    들을 수 있는 매우 매력적인(snatched) ‘아고라’로의
                    변신을 위한 첫 번째 도전이자 시작이다. <br/>
                    전시명&lt; 100% : this is who ‘I’am &gt;은 어떤 보상도
                    담보되지 않은 채 정답 없는 창작의 길을 걷는 예술가의
                    반문이자, 사회적 시선, 타인의 시선을 의식하지 않는
                    가장 솔직한 예술가 자신의 이야기를 상징한다. 
                    전시에 참여하는 아티스트는 다원화된 세계에서 서로
                    다른 개성의 광장이 되어 준다. 레드세다의 ‘꽃의 여정’은
                    작가 자신이자, 방황하는 청춘을 대변하며 꿈과 희망에
                    대한 각론을, 꿈의 공장이라는 의미의 핸즈 인 팩토리
                    (이재헌, 박태준, 하종훈)는 ‘아트토이’를 기획 제작
                    하고 유명 스포츠브랜드 협업을 진행하는 등 최근 
                    MZ세대의 에디션 작품 구입 보편화와 소장가치에 
                    관한 담론으로 이끌기에 충분하다. <br/>
                    NFT(Non-fungible token)작업을 시작으로 
                    비디오 클립, 독특한 캐릭터 필리를 자신의 페르소나로
                    페인팅 작업을 이어가는 비주는 최근 급부상한 NFT 
                    아트 정체와 실체에 관한 화두를 제공 할 것이다. 
                    초현실적인 이미지 조합으로 시대의 이슈를 다루는
                    레지나킴은 이번 전시 작품 중에 &lt; For Ukraine &gt; 
                    렌티큘러 작품을 선보이는데 간결한 이미지 이면에 
                    숨겨진 전쟁의 폐해에 대한 공론의 장을 선사할 것이라 기대한다.</p>
                </div>
                <div className="cont-en">
                  <p>Clayarch Gimhae Museum, which opened 
                      in 2006, has worked tirelessly over the past 
                      17 years to establish its identity as an 
                      architectural and ceramic museum, while 
                      highlighting the regional characteristics 
                      of Gimhae. The opening of the Cubic House 
                      exhibition space in 2012 expanded the 
                      scope of the museum’s exhibitions and 
                      curatorial efforts, as the museum’s reach 
                      extended into contemporary art as a whole. 
                      With the number of visitors reaching an 
                      all-time high in 2018–2019, the museum 
                      had seemingly begun to overcome the 
                      disadvantage of its limited accessibility 
                      and be sewn into the fabric of public daily 
                      life. However, in early 2020, the unexpected 
                      COVID-19 outbreak changed the way we 
                      lived, and the restrictions on physical 
                      contact between people forced us to face 
                      the inherent limitations of the physical 
                      space we think of as an “art museum”.
                      As we transition to a post-pandemic era 
                      marked by the spread of online exhibition 
                      culture and quantitative growth of video 
                      content, we want to challenge today’s 
                      stereotypical exhibition system, with the 
                      objective of “liberating the public from the
                      online world for a while and shifting them 
                      into the real world of the art museum.” 
                      This exhibition marks the first stand in 
                      this challenge, a new beginning towards 
                      transforming the exhibition space from a 
                      fixed and passive space for viewing art 
                      into an appealingly ‘snatched’ ‘agora’– a 
                      space for active participation and fun, where 
                      the voices of the younger generation and 
                      their social messages can be heard. 
                      The title of this exhibition, “100%: This Is 
                      Who ‘I’ Am,” is a reflection of a return 
                      question from an artist following a creative
                      path where there aren’t any guarantees 
                      of compensation or right answers. It 
                      symbolizes the utterly candid story of the 
                      artist who is not conscious of or bothered 
                      by the gaze of society or of others. The 
                      participating artists become platforms for 
                      respectively different personalities in a 
                      pluralistic world. Red Seda’s “Flor’s Journey” 
                      details hopes and dreams through 
                      representations of the artist herself as 
                      well as young people who are wandering 
                      at loose ends, while Hands In Factory 
                      (‘UpteMPo’ Jaeheon Lee, ‘RocKOON’ 
                      Taejun Park, and Jonghun Ha), an art team
                      involved in activities like devising and 
                      producing “Art Toys” and collaborating 
                      with well-known sports brands, invites us
                      into a discussion of the recent widespread
                      acquisition of limited edition items by 
                      Millennials and Generation Z and the value
                      of collectibles. Biju, who started out with 
                      NFTs (non-fungible tokens) and branched 
                      out into video clips and paintings of her 
                      character/persona ‘Pilly’, raises the subject 
                      of the identity and essence of NFTs, an 
                      artform that underwent a rapid rise in the 
                      not-too-distant past. Regina Kim, whose 
                      work features combinations of surreal 
                      images dealing with contemporary issues,
                      presents “For Ukraine”, a lenticular work 
                      meant to offer a forum for public discussion 
                      about the evils of war, hidden behind 
                      simple images.
                      </p>
                </div>
                </>
    )
};

export default ContData;

import { useState } from "react";
import ContList from './cont.list.js'
import { useNavigate } from 'react-router-dom';
import { DrawData2 } from './draw.data.js';


function Draw2(props){
    let navigate = useNavigate();
    let [num, setNum] = useState(0);

    return(
            <div id="drawWrap" className='draw-biju'>
              <div className="inner pa-t0 pa-b4">
                <div className="list-tit-wr">
                  <ul className="tit-wr flex" onClick={()=>{navigate('../pages/drawAll')}}>
                    <li className="tit-b text-al-le">{props.tit[2].title}</li>
                    <li className="tit-b text-al-ri">{props.tit[2].titleEn}</li>
                  </ul>
                  <div className={'zoom-btn-wr ' + ' flex ' + 'minus'+ (19 + num) + ' puls'+ (19 + num)}>
                    <button onClick={()=>{ setNum(num - 3)}} className='zoom-btn zoom-btn-minus'><img src={process.env.PUBLIC_URL + '/img/icon_btn_minus.png'} /></button>
                    <button onClick={()=>{ setNum(num + 3)}} className='zoom-btn zoom-btn-puls'><img src={process.env.PUBLIC_URL + '/img/icon_btn_puls.png'} /></button>
                  </div>
                </div>              
                <div className={'cont '+'font-'+(19 + num)}>
                  <div className="draw-img-wr">
                    <div className="draw-img draw-img01">
                      <div className="img-box">
                        <img src={process.env.PUBLIC_URL + '/img/img_sp_biju.jpg'} />
                      </div>
                      <div className="artist-tit">
                        <span>Artist</span>
                        <h3>비주 <br/>Biju</h3>
                      </div>
                    </div>                    
                  </div>
                  <ul className="data-list-wr">
                  {
                    DrawData2.map(function(a, i){
                        return(
                                <li className='data-list flex' key={i}>
                                    <div className="num-ci"><span>{i + 1}</span></div>
                                    <img src={process.env.PUBLIC_URL + '/img/img_list2_' + (i+1) + '.png'} />
                                    <div className="list-tit">
                                        <div className="tit-b">{DrawData2[i].title}<i className='i-txt'> {DrawData2[i].titlesub}</i></div>
                                        <p>{DrawData2[i].category}</p>
                                        <p>{DrawData2[i].size}</p>
                                    </div>
                                </li>
                        )
                      })
                    }
                  </ul>
                  <ContList />
                  <div className="cont-btn">
                    <button onClick={()=>{navigate('../pages/draw1')}}><img src={process.env.PUBLIC_URL + '/img/icon_prev.png'} alt="next button" /></button>
                    <button onClick={()=>{navigate('/')}}><img src={process.env.PUBLIC_URL + '/img/icon_home.png'} alt="home button" /></button>   
                    <button onClick={()=>{navigate('../pages/draw3')}}><img src={process.env.PUBLIC_URL + '/img/icon_next.png'} alt="next button" /></button>      
                </div>
                  
                </div>
              </div>
            </div>
    )
}

export default Draw2;